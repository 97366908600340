import { Directive, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[appHoverStyle]',
  standalone: true,
})
export class HoverStyleDirective {
  @HostBinding('style.opacity') 'opacity' = 1;

  @HostBinding('style.cursor') 'cursorType' = 'pointer';

  @HostListener('mouseenter') mouseover() {
    this.opacity = 0.7;
  }

  @HostListener('mouseleave') mouseleave() {
    this.opacity = 1;
  }
}
